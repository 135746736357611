import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/store",
    component: () => import("@/views/Store.vue"),
    children: [
      { path: "", redirect: "/store/orders" },
      {
        path: "login",
        name: "storeLogin",
        component: () => import("@/views/Login"),
      },
      {
        path: "register",
        component: () => import("@/views/Register"),
      },
      {
        path: "orders",
        name: "StoreOrder",
        component: () => import("../views/Orders"),
      },
      {
        path: "profile",
        name: "StoreProfile",
        component: () => import("@/views/Profile"),
      },
      {
        path: "scan",
        name: "scan",
        component: () => import("@/views/Scan"),
      },
      {
        path: "scanResult",
        name: "scanResult",
        component: () => import("@/views/ScanResult"),
      },
      {
        path: "changePassword",
        name: "changePassword",
        component: () => import("@/views/ChangePassword"),
      },
      {
        path: "resetParssword",
        name: "resetParssword",
        component: () => import("@/views/ResetPassword"),
      },
      {
        path: "forgotPassword",
        component: () => import("@/views/ForgotPassword"),
      },
      {
        path: "setStock",
        component: () => import("@/views/SetStock"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/store",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}
export default router;
