import request from "@/utils/request";

// 查询商品分类列表
export function loadOrders(query) {
  return request({
    url: "/special/orders",
    method: "get",
    params: query,
  });
}
export function loadOrderByCode(code) {
  return request({
    url: "/special/order/"+code,
    method: "get"
  });
}

export function queryDict(dictType) {
  return request({
    url: "/system/dict/data/type/" + dictType,
    method: "get",
  });
}

export function getRecords() {
  return request({
    url: "/special/getRecords",
    method: "get",
  });
}


export function userOrderCancel(orderId) {
  return request({
    url: "/special/userOrderCancel/" + orderId,
    method: "post",
  });
}

export function orderConfirm(orderId) {
  return request({
    url: "/special/orderConfirm/" + orderId,
    method: "post",
  });
}

export function orderCompleted(orderId) {
  return request({
    url: "/special/orderCompleted/" + orderId,
    method: "post",
  });
}
export function addOrder(data) {
  return request({
    url: "/special/addOrder",
    method: "post",
    data
  });
}
// 新增积分记录
export function addScoreRecord(data) {
  return request({
    url: "/special/scoreRecord",
    method: "post",
    data: data,
  });
}

// 新增积分记录
export function getDistributors() {
  return request({
    url: "/getDistributors",
    method: "get",
    headers: {
      isToken: false
    },
  });
}


export function getTopProducts(query) {
  return request({
    url: "/special/getTopProducts",
    method: "get",
    params: query,
  });
}


export function changePassword(data) {
  return request({
    url: "/special/changePassword",
    method: "post",
    data,
  });
}

export function forgetPassword(data) {
  return request({
    url: "/special/forgetPassword",
    method: "post",
    data,
  });
}

export function setStock(data) {
  return request({
    url: "/setStock",
    method: "post",
    data,
  });
}



